<template>
  <v-navigation-drawer
    clipped
    color="#F5F8FC"
    floating
    v-model="drawer"
    app
    temporary
    width="300px"
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title
          class="tf-font py-1"
          style="font-size: 130%; font-weight: 400"
        >
          TechFerment
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>

    <v-list dense shaped>
      <!-- Home -->
      <v-list-item to="/" dense color="primary">
        <v-list-item-icon>
          <v-icon>mdi-home-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-title>Home</v-list-item-title>
      </v-list-item>
      <!-- Home -->

      <!-- Events -->
      <v-list-item to="/events" dense color="primary">
        <v-list-item-icon color="primary">
          <v-icon>mdi-table</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Events</v-list-item-title>
      </v-list-item>
      <!-- Events -->

      <!-- Speakers -->
      <v-list-item to="/team" dense color="primary">
        <v-list-item-icon color="primary">
          <v-icon>mdi-account-group-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Team</v-list-item-title>
      </v-list-item>

      <v-list-item to="/about" dense color="primary">
        <v-list-item-icon color="primary">
          <v-icon>mdi-information-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>About</v-list-item-title>
      </v-list-item>
      <!-- Speakers -->
    </v-list>
    <!-- Management -->
    <!-- Brand Requirement -->
  </v-navigation-drawer>
</template>
    
    <script>
export default {
  name: "AppDrawer",
  data: () => ({
    mini: true,
  }),
  mounted() {},
  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    currentUser: {
      get() {
        return this.$store.state.authStore.currentUser;
      },
    },
  },
};
</script>
    
<template>
    <v-footer height="auto" color="white" class="tf-font">
      <v-container fluid class="px-0 mx-0">
        <v-row align="center" justify="center">
          <v-col md="11" lg="11">
            <!-- <v-divider></v-divider> -->
            <v-fab-transition>
              <v-btn
                aria-label="FAB Button"
                style="
                  margin-top: -7px;
                  margin-right: 12%;
                "
                color="white"
                absolute
                top
                right
                fab
                @click="toTop"
              >
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn>
            </v-fab-transition>
            <socialMediaVue class="mb-3 mt-n2" />
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col md="11" lg="11" class="my-0">
            <v-container fluid class="my-0 py-0 px-0">
              <v-row>
                <v-col md="3">
                  <p class="mb-2" style="font-size: 140%; font-weight: 300">
                    <b>About</b>
                  </p>
                  <div v-for="(item, i) in aboutLinks" :key="i">
                    <router-link
                      :to="item.link"
                      rel="noreferrer"
                      class="my-3"
                      style="text-decoration: none; font-size: 100%; color: #3e3e3e"
                      >{{ item.name }}</router-link
                    ><br />
                  </div>
                </v-col>
                <v-col md="3">
                  <p class="mb-2" style="font-size: 140%; font-weight: 300">
                    <b>Resources</b>
                  </p>
                  <div v-for="(item, i) in Resources" :key="i">
                    <a
                      :href="item.link"
                      rel="noreferrer"
                      class="my-3"
                      target="_blank"
                      style="text-decoration: none; font-size: 100%; color: #3e3e3e"
                      >{{ item.name }}</a
                    ><br />
                  </div>
                </v-col>
                <!-- <v-col md="3">
                    <p
                      class=" mb-2"
                      style="font-size: 140%; font-weight: 300"
                    >
                      <b>Additional Resources</b>
                    </p>
                    <div v-for="(item, i) in adResources" :key="i">
                      <a
                        :href="item.link"
                        rel="noreferrer"
                        class=" my-3"
                        target="_blank"
                        style="
                          text-decoration: none;
                          font-size: 100%;
                          color: #3e3e3e;
                        "
                        >{{ item.name }}</a
                      ><br />
                    </div>
                  </v-col> -->
              </v-row>
            </v-container>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col md="11" lg="11" class="my-0">
            <v-divider class="mb-4"></v-divider>
            <span class="mr-5" style="font-size: 22px"> TechFerment </span>
            <div class="float-md-right mt-2">
              <!-- <br class="hidden-md-and-up" /> -->
              <!-- <router-link
                to="/code-of-conducts"
                target="_blank"
                rel="noreferrer"
                class="mr-4"
                style="text-decoration: none; color: #e0e0e0smoke"
                >Code of Conducts</router-link
              > -->
              <!-- <br class="hidden-md-and-up" /> -->
              <!-- <a
                  href="https://gdg.community.dev/participation-terms/"
                  target="_blank"
                  rel="noreferrer"
                  class=" mr-4 "
                  style="text-decoration: none;color: #e0e0e0smoke;"
                  >Terms & Conditions</a
                >
                <br class="hidden-md-and-up" /> -->
  
              <!-- <a
                  href="https://developers.google.com/community-guidelines"
                  target="_blank"
                  rel="noreferrer"
                  class=" mr-4 "
                  style="text-decoration: none;color: #e0e0e0smoke;"
                  >Community Guidelines</a
                > -->
              <!-- <br class="hidden-md-and-up" /> -->
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </template>
    
    <script>
  import socialMediaVue from "@/components/common/SocialMedia.vue";
  
  export default {
    data: () => ({
      aboutLinks: [
        {
          name: "TechFerment",
          link: "/about",
        },
        {
          name: "Team",
          link: "/team",
        },
        {
          name: "Events",
          link: "/events",
        },
      ],
      Resources: [
        {
          name: "Become a Member",
          link: "https://bit.ly/tf-members",
        },
        {
          name: "Call for Speakers",
          link: "https://bit.ly/tf-call-for-speakers",
        },
        {
          name: "Host TechFerment",
          link: "https://bit.ly/host-techferment",
        },
      ],
      // adResources: [
      //   {
      //     name: "Terms & Conditions",
      //     link: "/terms-and-conditions",
      //   },
      //   {
      //     name: "Privacy & Policy",
      //     link: "/privacy-and-policy",
      //   }
      // ],
    }),
    components: {
      socialMediaVue,
    },
    methods: {
      toTop() {
        this.$vuetify.goTo(0);
      },
    },
  };
  </script>
    
    <style>
  </style>
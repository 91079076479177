<template>
  <v-app-bar
    flat
    fixed
    app
    class="px-2 mx-6 mt-5 mt-md-3 mx-md-auto tf-font"
    style="
      align-items: center;
      max-width: 1024px;
      margin: auto;
      border-radius: 20px;
      -webkit-backdrop-filter: saturate(100%) blur(5px);
      backdrop-filter: saturate(100%) blur(10px);
      background-color: white;
      border: 0.01px solid #3281d5 !important;
      background-color: rgba(255, 255, 255, 0.6);
    "
  >
    <v-app-bar-nav-icon class="d-md-none d-lg-none" @click="toggleDrawer"></v-app-bar-nav-icon>

    <v-toolbar-title class="mr-4 px-0">
      <router-link to="/" style="text-decoration: none; font-size: 100%">
        <!-- TechFerment -->
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          :src="require('@/assets/img/logo.png')"
          width="150"
        />
      </router-link>
    </v-toolbar-title>

    <div class="hidden-sm-and-down">
      <v-btn rounded text small class="mr-1" color="primary" to="/events"
        >Events</v-btn
      >
      <!-- <v-btn rounded text small class="mr-1" color="primary" to="/team"
        >Team</v-btn
      > -->
      <!-- <v-btn rounded text small class="mr-1" color="primary" to="/bootcamps"
        >Bootcamp</v-btn
      > -->
      <v-btn rounded text small class="mr-1" color="primary" to="/about"
        >About</v-btn
      >
    </div>

    <v-spacer></v-spacer>

    <!-- <v-btn icon>
      <v-icon>mdi-magnify</v-icon>
    </v-btn>

    <v-btn icon>
      <v-icon>mdi-heart</v-icon>
    </v-btn>

    <v-btn icon>
      <v-icon>mdi-dots-vertical</v-icon>
    </v-btn> -->
    <!-- <v-list class="py-0 my-0 hidden-sm-and-down" >
      <v-list-item>
        <v-list-item-avatar>
          <v-img src="https://picsum.photos/200/"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Test</v-list-item-title>
          <v-list-item-subtitle>Test lasdas</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list> -->
    <!-- <v-btn rounded depressed small color="primary" target="_blank" href="https://bootcamp.techferment.com"
        >Bootcamp</v-btn
      > -->
  </v-app-bar>
</template>

<script>
export default {
  methods:{
    toggleDrawer() {
      this.$store.commit("TOGGLE_DRAWER");
    },
  }
};
</script>

<style>
</style>
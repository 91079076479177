<template>
  <v-app style="background-color: #f5f8fc">
    <AppToolbarVue />
    <AppDrawerVue/>
    <v-main>
      <v-slide-y-reverse-transition>
        <!-- $route.params.id!=bannerInfo.eventURL -->
        <v-container fluid v-if="bannerInfo " class="px-0">
          <v-row align="center" justify="center">
            <v-col
              md="12"
              lg="12"
              xl="12"
              class="text-center my-0 text-sm-center white--text my-0 px-4 mx-2"
              style="border-radius: 7px"
              :style="{ backgroundColor: bannerInfo.bannerColor }"
            >
              <p class="pt-4 pb-2 google-font mb-0">
                <span class="mr-md-3">{{ bannerInfo.bannerText }}</span>
                <br class="d-block d-sm-none" />
                <router-link
                  class=""
                  :to="'/events/' + bannerInfo.eventURL"
                  style="color: white"
                  >See More</router-link
                >
                {{ }}
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-slide-y-reverse-transition>
      <v-slide-y-reverse-transition hide-on-leave leave-absolute>
        <router-view />
      </v-slide-y-reverse-transition>
    </v-main>
    <AppFooterVue />
  </v-app>
</template>

<script>
import AppDrawerVue from './components/core/AppDrawer.vue';
import AppFooterVue from "./components/core/AppFooter.vue";
import AppToolbarVue from "./components/core/AppToolbar.vue";

export default {
  name: "App",
  components: {
    AppToolbarVue,
    AppFooterVue,
    AppDrawerVue
  },
  data: () => ({
    //
    bannerInfo: null,
  }),
  mounted() {
    this.getBanner();
  },
  methods: {
    async getBanner() {
      try {
        let res = await this.$store.dispatch("eventStore/getBanner");
        res = await res;
        if (res.success && res.data.bannerStatus) {
          this.bannerInfo = await res.data;
          if(this.bannerInfo.eventId.length){
            let edata = await this.$store.dispatch('eventStore/getEventInfoByDocId', this.bannerInfo.eventId)
            edata = await edata
            if(edata.success){
              this.bannerInfo.eventURL = edata.data.id
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

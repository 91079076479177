/**
 * ES Module for Firebase Auth Service
 * Author: Vrijraj Singh - https://vrijraj.xyz
 * Firebase SDK Version: 9
*/
import { db } from "@/config/firebase";
import {
    collection,
    getDocs,
    doc,
    getDoc,
    where,
    query
} from "firebase/firestore";

const state = {

}

const mutations = {
    doNothing: (state) => (state)
}

const getters = {

}

const actions = {
    async getAllEvents({ commit }) {
        let events = []
        return new Promise((resolve, reject) => {
            getDocs(collection(db, "events")).then((querySnapshot => {
                querySnapshot.forEach(doc => {
                    events.push({ ...doc.data(), ...{ docid: doc.id } })
                })
                commit('doNothing')
                resolve({
                    success: true,
                    data: events
                })
            })).catch(err => {
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },

    async getBanner({ commit }) {
        return new Promise((resolve, reject) => {
            let docRef = doc(db, 'config', "banner")
            getDoc(docRef).then(docSnap => {
                if (docSnap.exists()) {
                    commit('doNothing')
                    resolve({
                        success: true,
                        data: docSnap.data()
                    })
                } else {
                    resolve({
                        success: false,
                        message: 'No Data Found'
                    })
                }
            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },

    async getEventInfo({ commit }, id) {
        let events = []
        return new Promise((resolve, reject) => {
            const docRef = collection(db, "events");
            let q = query(docRef, where("id", "==", id))

            getDocs(q).then(querySnapshot => {
                if (querySnapshot.docs.length) {
                    querySnapshot.forEach(doc => {
                        events.push({ ...doc.data(), ...{ docid: doc.id } })
                    })
                    resolve({
                        success: true,
                        data: events
                    })
                    
                } else {
                    resolve({
                        success: false,
                        message: 'No Event Found'
                    })
                }

            }).catch(err => {
                console.log('err', err);
                reject({
                    success: false,
                    error: err
                })
            })
            commit('doNothing')
        })
    },

    async getEventInfoByDocId({ commit }, docid) {
        return new Promise((resolve, reject) => {
            let docRef = doc(db, 'events', docid)
            commit('doNothing')
            getDoc(docRef).then(docSnap => {
                if (docSnap.exists()) {
                    resolve({
                        success: true,
                        data: { ...docSnap.data(), ...{ docid: docid } }
                    })
                } else {
                    resolve({
                        success: false,
                        message: 'No Data Found'
                    })
                }
            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
<template>
  <v-main class="ma-0 pa-0">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col md="11">
          <!-- Hero -->
          <v-container fluid class="my-10">
            <v-row justify="center" align="center">
              <v-col md="7" cols="12" order-md="1" order="2">
                <p class="tf-heading">
                  A Community of <span style="color: #3281D5;">Engineers</span> <br />
                  For <span style="color: #3281D5;">Engineers</span> 
                </p>
                <p class="tf-font" style="font-size: 110%">
                  Our mission is to equip our community members with practical
                  skills, enabling them to communicate their insights and drive
                  innovative solutions effectively.
                </p>

                <SocialMediaVue />
                <v-btn depressed rounded color="primary" class="my-5" href="https://bit.ly/tf-members" target="_blank"
                  >Become a Member</v-btn
                >
              </v-col>
              <v-col md="5" cols="12" order-md="2" order="1"> 
                <v-img :src="require('@/assets/img/home.svg')"></v-img>
              </v-col>
            </v-row>
          </v-container>
          <!-- Hero -->

          <!-- About Community -->
          <v-container fluid class="tf-font">
            <v-row>
              <v-col md="8">
                <div
                  style="background: white; border-radius: 15px"
                  class="px-5 py-4 px-md-10 mt-3"
                >
                  <p
                    class="mb-1 mt-5"
                    style="font-size: 34px; font-weight: 500; color: #212121"
                  >
                    Community of Engineers
                  </p>
                  <p>
                    We are a thriving engineering community, dedicated to
                    fostering a network of inquisitive individuals. Our focus is
                    on providing startups and enterprises with practical
                    solutions to their technical challenges. Our community
                    members are equipped with the skills necessary to
                    effectively communicate their expertise, allowing us to
                    collectively drive innovative and impactful solutions.
                    Whether you are just starting out in your engineering
                    journey or are an experienced professional, we believe that
                    there is always more to learn, and we are passionate about
                    supporting and empowering one another in this pursuit. Join
                    us in building a strong and dynamic engineering community,
                    dedicated to solving the most challenging technical
                    problems.
                  </p>

                  <span v-for="(ele, index) in technologies" :key="index">
                    <v-chip
                      class="mr-2 mt-2"
                      outlined
                      color="black"
                      style="font-size: 15px"
                      >{{ ele }}</v-chip
                    >
                  </span>

                  <p class="mt-5" style="font-size: 15px">
                    and Keep your seat belt fastened and get ready to explore.
                  </p>
                </div>
              </v-col>
              <v-col md="4" class="hidden-sm-and-down">
                <div
                  style="background: white; border-radius: 15px"
                  class="px-5 py-4 px-md-10 mt-3"
                >
                <v-img :src="require('@/assets/img/extra.jpg')" height="420"></v-img>
                  <!-- <p
                    class="mb-1 mt-5"
                    style="font-size: 34px; font-weight: 500; color: #212121"
                  >
                    About TechFerment
                  </p>

                  <span v-for="(ele, index) in technologies" :key="index">
                    <v-chip
                      class="mr-2 mt-2"
                      outlined
                      color="black"
                      style="font-size: 15px"
                      >{{ ele }}</v-chip
                    >
                  </span>

                  <p class="mt-5" style="font-size: 15px">
                    and Keep your seat belt fastened and get ready to explore.
                  </p> -->
                </div>
              </v-col>
            </v-row>
          </v-container>
          <!-- About Community -->

          <!-- Overview Community -->
          <v-container fluid class="tf-font">
            <v-row>
              <v-col md="12" sm="12">
                <p
                  class="mb-1 mt-5 text-center"
                  style="font-size: 34px; font-weight: 500; color: #212121"
                >
                  Community where you can
                </p>
              </v-col>
              <v-col md="4" sm="4" cols="12" v-for="(item, index) in why" :key="index">
                <div
                  style="background: white; border-radius: 15px"
                  class="px-5 py-4 px-md-10 mt-3"
                >
                  <p
                    class="mb-1 mt-5"
                    style="font-size: 24px; font-weight: 500; color: #212121"
                  >
                    {{ item.title }}
                  </p>
                  <p class="mt-5" style="font-size: 15px">
                    {{ item.desc }}
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <!-- Overview Community -->

          <!-- Community Connect -->
          <v-container fluid class="tf-font my-10">
            <v-row justify="center" align="center">
              <v-col md="3" v-for="(item, index) in connect" :key="index">
                <div
                  style="background: white; border-radius: 15px"
                  class="pa-8 px-md-10 mt-3 text-center"
                >
                  <v-icon size="40">{{ item.icon }}</v-icon>
                  <p
                    class="mt-1"
                    style="font-size: 24px; font-weight: 500; color: #212121"
                  >
                    {{ item.title }}
                  </p>
                  <p class="my-0" style="font-size: 15px">
                    {{ item.desc }}
                  </p>
                  <v-btn
                    class="mt-4"
                    rounded
                    target="_blank"
                    text
                    :href="item.link.to"
                    >{{ item.link.label }}</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </v-container>
          <!-- Community Connect -->
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import SocialMediaVue from "@/components/common/SocialMedia.vue";

export default {
  name: "HomePage",
  mounted(){
    document.title = 'TechFerment'
  },
  data: () => ({
    why: [
      {
        title: "Connect",
        desc: "Gather with local tech experts and engineers. Everyone is invited, regardless of their diverse backgrounds, employers, or fields.",
      },
      {
        title: "Learn",
        desc: "Acquire new technical knowledge and skills through various hands-on activities such as workshops, training sessions, events, presentations, and in-person or online meetups.",
      },
      {
        title: "Grow",
        desc: "Utilize knowledge and connections to create great products, advance skills, and network. Help the community learn by sharing knowledge.",
      },
    ],
    connect: [
      {
        title: "Connect with us!",
        desc: "Stay connected for all the recent updates from us.",
        icon: "mdi-account-multiple-check",
        link: {
          label: "Connect with us!",
          to: "https://bit.ly/tf-members",
        },
      },
      {
        title: "Blogs",
        desc: "Learn in-depth tech from our exclusive blogs.",
        icon: "mdi-file-document",
        link: {
          label: "Read Now",
          to: "https://medium.com/techferment",
        },
      },
      {
        title: "YouTube",
        desc: "Subscribe to get updates about our exclusive content.",
        icon: "mdi-youtube",
        link: {
          label: "Watch Now",
          to: "https://www.youtube.com/c/techferment?sub_confirmation=1",
        },
      },
    ],
    technologies: [
      "Android",
      "Cloud",
      "Design",
      "Firebase",
      "Flutter",
      "ML/AI",
      "HTML",
      "CSS",
      "JavaScript",
      "Cyber Security",
      "Node.js",
      "Angular",
      "React",
    ].sort(),
  }),
  components: {
    SocialMediaVue,
  },
};
</script>

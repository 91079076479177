/**
 * ES Module for Firebase Auth Service
 * Author: Vrijraj Singh - https://vrijraj.xyz
 * Firebase SDK Version: 9
*/
import { db } from "@/config/firebase";
import {
    collection,
    getDocs,
    doc,
    getDoc,
    query,
    where
} from "firebase/firestore";

const state = {

}

const mutations = {
    doNothing: (state) => (state)
}

const getters = {

}

const actions = {
    // async getAllSpeakers({ commit }) {
    //     let speakers = []
    //     return new Promise((resolve, reject) => {
    //         // query(citiesRef, orderBy("name"), limit(3))
    //         getDocs(collection(db, "speakers")).then((querySnapshot => {
    //             querySnapshot.forEach(doc => {
    //                 speakers.push({ ...doc.data(), ...{ docid: doc.id } })
    //             })
    //             commit('doNothing')
    //             resolve({
    //                 success: true,
    //                 data: speakers
    //             })
    //         })).catch(err => {
    //             reject({
    //                 success: false,
    //                 error: err
    //             })
    //         })
    //     })
    // },

    async getSpeakerInfo({ commit }, docid) {
        return new Promise((resolve, reject) => {
            let docRef = doc(db, 'speakers', docid)
            commit('doNothing')
            getDoc(docRef).then(docSnap => {
                if (docSnap.exists()) {
                    delete docSnap.data().mobile
                    delete docSnap.data().email
                    resolve({
                        success: true,
                        data: { ...docSnap.data(), ...{ docid: docid } }
                    })
                } else {
                    resolve({
                        success: false,
                        message: 'No Data Found'
                    })
                }
            }).catch(err => {
                console.log('Err: ', err);
                reject({
                    success: false,
                    error: err
                })
            })

        })
    },

    async getEventInfoBySpeaker({ commit }, docid) {
        let events = []
        return new Promise((resolve, reject) => {
            const docRef = collection(db, "events");
            let q = query(docRef, where("speakers", "array-contains", docid))

            getDocs(q).then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    events.push({ ...doc.data(), ...{ docid: doc.id } })
                })
                resolve({
                    success: true,
                    data: events
                })
                commit('doNothing')
            }).catch(err => {
                console.log('err', err);
                reject({
                    success: false,
                    error: err
                })
            })
        })
    },
    
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
<template>
    <div>
      <v-btn
        icon
        color="white"
        v-for="(item, index) in links"
        :key="index"
        :href="item.link"
        target="_blank"
        class="mr-2 white"
        
      >
        <v-icon color="black">{{ item.icon }}</v-icon>
      </v-btn>
    </div>
  </template>
    
    <script>
  export default {
    name: "SocialMediaIcon",
    data: () => ({
      links: [
        {
          name: "Twitter",
          link: "https://twitter.com/TechFerment",
          icon: "mdi-twitter",
        },
        {
          name: "LinkedIn",
          link: "https://www.linkedin.com/company/techferment/",
          icon: "mdi-linkedin",
        },
        {
          name: "Instagram",
          link: "http://instagram.com/techferment",
          icon: "mdi-instagram",
        },
        {
          name: "Facebook",
          link: "http://facebook.com/techferment",
          icon: "mdi-facebook",
        },
        {
          name: "YouTube",
          link: "https://www.youtube.com/c/techferment",
          icon: "mdi-youtube",
        },
        // {
        //   name: "Discord",
        //   link: "https://discord.techferment.com",
        //   icon: "mdi-discord",
        // },
      ],
    }),
  };
  </script>
    
    <style>
  </style>